import leaveRoutes from '@/apps/leave-management/router/leaveRoutes';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFound from '../apps/PageNotFound.vue';
import { getToken, getUserid, isLoggedIn, logout } from '../services/auth';
import { encodeUrlPath } from '@/helpers/utils';

const route: Array<RouteRecordRaw> = [
  {
    path: '/logout',
    name: 'logout',
    alias: '/logout',
    component: () => '',
    meta: { auth: false },
    beforeEnter: (_to, _from, next) => {
      logout();
      next('/login');
    }
  },
  {
    path: '/login',
    name: 'login',
    alias: '/login',
    component: () =>
      (window.location.href = `${process.env.VUE_APP_MAIN_PAGE_URL}login`),
    meta: { auth: false }
  },
  {
    path: '/legacy',
    name: 'legacy',
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;
const isLeaveRoutesEnabled = !env.VUE_APP_LEAVE_ROUTES_ENABLED;

export const routes = route.concat(isLeaveRoutesEnabled ? leaveRoutes : []);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.meta.auth && to.query['app-token']) {
    next();
  } else if (to.meta.auth && !isLoggedIn()){
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href =  `${env.VUE_APP_MAIN_PAGE_URL}sso-login?redirect-to=${redirect}`;
  } else {
    next();
  }
});

export default router;
