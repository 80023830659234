<template>
  <template v-if="!env.VUE_APP_LEAVE_ACCESS_LEVEL &&
    leavesSettingsPerm.view_leave_access_level
  ">
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent v-if="tableData.length === 0 && showOnboarding" class="z-10" @btncall="setLocal"
      title="Welcome to Access Levels"
      content="On this page, you can easily manage access levels for your organization. Click on create role to get started now!" />

    <div v-if="showSection === 'view-access-levels'">
      <div>
        <!-- Message Alert -->
        <div v-if="tableData.length === 0 && showAlertMessage" class="
            mt-4
            flex
            border-2 border-solid border-[#E6E8F0]
            rounded-lg
            bg-[#FAFBFF]
            gap-x-[0.69rem]
            py-2
            p-[0.69rem]
            justify-between
            items-center
          ">
          <div class="flex items-center gap-x-4">
            <div class="">
              <InfoClose />
            </div>
            <p class="text-base N700 py-2">
              Access levels determine the extent of permissions and actions a
              user can perform within the app based on their assigned role.
              <span class="O400">Click create role</span>
            </p>
          </div>
          <span @click="showAlertMessage = false" type="button">
            <CloseCircle />
          </span>
        </div>

        <!-- Leave type section -->
        <div class="
            flex flex-col
            items-center
            justify-between
            gap-5
            my-2
            lg:flex-row lg:gap-0
          ">
          <div class="flex items-center gap-x-2">
            <FilterComponent data-cy="filterButton" @click="toggleFilters" />
            <SearchComponent data-cy="search" class="py-2" @search="searchTableData" />
          </div>
          <div v-if="leavesSettingsPerm.manage_leave_access_level" class="flex gap-2">
            <ButtonComponent buttonLabel="Create role" variant="primary" data-cy="addAccessLevel"
              @click="handleCreateRoleClick()" class="shadow-md" />
          </div>
        </div>

        <div v-show="showFilters" class="flex gap-2 items-center mb-3">
          <p class="N900">Filter</p>
          <div class="holidaySelect">
            <SelectFieldValue :showSearch="false" :showLabel="false" id="status" data-cy="filterField"
              placeholder="Filter" :options="['Unarchived', 'Archived']" v-model="selectedFilter" />
          </div>
        </div>

        <TableComponentVue :overflowXAuto="false" :headers="displayedHeaders" :items="tableData" :itemsPerPage="9"
          emptyMessage="You haven't created any access level for your organisation">
          <template #name="slotProps">
            <span class="flex items-center gap-2 py-3">
              {{ (slotProps.item as LeaveAccessLevels)["name"] }}
            </span>
          </template>

          <template #permissions="slotProps">
            <div class="flex">
              <div v-for="(permission, key, index) in {
                ...slotProps.item?.permissions?.settings?.leave_management,
                ...slotProps.item?.permissions?.settings?.approvers
              }" :key="key">
                <ul v-if="index < 1">
                  <li v-if="permission" :key="key">
                    <span class="
                        text-[#19191a]
                        description
                ml-3
                bg-[#EDEFF5]
                p-2
                rounded-full
                cursor-pointer
                      ">
                      {{ permissionNames[key] }}
                    </span>
                  </li>
                </ul>
              </div>
              <div v-if="Object.values({
                ...slotProps.item?.permissions?.settings?.leave_management,
                ...slotProps.item?.permissions?.settings?.approvers
              }).filter(Boolean).length > 1
              ">
                <span class="
                description
                ml-3
                bg-[#EDEFF5]
                p-2
                rounded-full
                cursor-pointer
                  ">
                  +{{
                    Object.values({
                      ...slotProps.item?.permissions?.settings
                        ?.leave_management,
                      ...slotProps.item?.permissions?.settings?.approvers
                    }).filter(Boolean).length - 1
                  }}
                  more

                  <div class="
                  tooltip-text
                    ">
                    <div v-for="(permission, key, index) in {
                      ...slotProps.item?.permissions?.settings
                        ?.leave_management,
                      ...slotProps.item?.permissions?.settings?.approvers
                    }" :key="key">
                      <span class="flex" v-if="index > 0 && permission">{{
                        permissionNames[key]
                      }}<span class="flex"
                          v-if="index !== Object.keys({ ...slotProps.item?.permissions?.settings?.leave_management, ...slotProps.item?.permissions?.settings?.approvers }).length - 1 && permission">&nbsp;&nbsp;/</span></span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </template>

          <template #action="slotProps">
            <div class="flex justify-start">
              <DropdownMenu :isLastItem="slotProps.displayedItems > 3 ? slotProps.displayedItems - 1 === slotProps.index ||
                slotProps.displayedItems - 2 === slotProps.index ||
                slotProps.displayedItems - 3 === slotProps.index :
                slotProps.displayedItems - 1 === slotProps.index ||
                slotProps.displayedItems - 2 === slotProps.index" :bottom-class="'-mt-[16rem] absolute z-50'"
                data-cy="actions" @selectOption="(name: string) => handleSelectOption(slotProps.item, name)"
                :optionsList="[
                  {
                    id: 1,
                    name: 'Edit',
                    label: 'Edit',
                    icon: EditIcon,
                    fill: '#696F8C',
                    allowAccess: true
                  },
                  {
                    id: 2,
                    name: 'View',
                    label: 'View People',
                    icon: PersonIcon,
                    fill: '#696F8C',
                    allowAccess: true
                  },
                  {
                    id: 3,
                    name: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                    label: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                    icon: ArchiveSvg,
                    fill: '#696F8C',
                    allowAccess: true
                  },
                  {
                    id: 4,
                    name: 'Remove',
                    label: 'Remove',
                    icon: TrashIcon,
                    fill: '#696F8C',
                    allowAccess: true
                  }
                ]" />
            </div>
          </template>
        </TableComponentVue>

        <!-- Loading state -->
        <div v-if="queryLoading">
          <LoaderIconVue />
        </div>
      </div>
    </div>

    <!-- ACCESS LEVELS SETTINGS -->
    <div v-if="showSection === 'add-edit-role'">
      <LoaderIconVue v-if="!showContent" />
      <div v-else>
        <!-- Role Creation Header -->
        <h3 class="H700 N900 pt-4">
          {{ editMode ? 'Edit role' : 'Create role' }}
        </h3>

        <!-- Message Alert -->
        <div v-if="tableData.length === 0 && showAlertMessage" class="
            mt-4
            flex
            border-2 border-solid border-[#E6E8F0]
            rounded-lg
            bg-[#FAFBFF]
            gap-x-[0.69rem]
            py-2
            p-[0.69rem]
            justify-between
            items-center
          ">
          <div class="flex items-center gap-x-4">
            <div>
              <InfoClose />
            </div>
            <p class="text-base N700">
              Assigning roles is crucial for providing specific access within
              the app. It ensures that individuals have the right permissions
              and functionalities based on their designated roles, optimizing
              both security and user experience.
            </p>
          </div>
          <span @click="showAlertMessage = false" type="button">
            <CloseCircle />
          </span>
        </div>

        <!-- Role Name and Position Selection -->
        <div class="flex flex-row gap-4 w-full pt-5 items-center">
          <div class="w-[50vw]">
            <InputFieldValue data-cy="role" label="Role name" placeholder="Name" :requireTag="true" :showlength="false"
              v-model="roleName" />
          </div>
          <div class="w-[45vw] mb-2">
            <div class="flex flex-col w-full">
              <Multiselect data-cy="employees" :options="leaveemployees" v-model="selectedEmployees" :multiple="true"
                :closeOnSelect="false" :blockKeys="['Delete']" :groupSelect="true" :clearOnSelect="false"
                :preserveSearch="true" :hideSelected="true" :taggable="true" :param="'name'" :trackBy="'id'"
                :label="' Employees'" :placeholder="'Assign employees to role'" :requireTag="true" />
            </div>
          </div>
        </div>

        <!-- Permissions Section -->
        <div class="my-5 pt-10">
          <h3 class="H700 N900">Access Level</h3>
          <p class="text-base N700 py-2">
            Access levels determine the extent of permissions and actions a user
            can perform within the app based on their assigned role.
          </p>
        </div>

        <HorizontalLine class="mt-5" />
        <div>
          <div>
            <div class="my-5">
              <!-- Permissions Title -->
              <h3 class="H700 N900">Settings</h3>
              <div class="
                  flex flex-row
                  gap-4
                  items-center
                  justify-between
                  overflow-x-auto
                ">
                <!-- Permissions List -->
                <div class="flex flex-col items-center py-5">
                  <div class="
                      flex flex-row
                      items-center
                      justify-between
                      gap-5
                      w-[30rem]
                    ">
                    <p class="text-base N700 mb-3">Leave Types</p>
                    <label class="flex items-center gap-2 pb-2">
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="viewLeaveTypesChecked" />
                        View
                      </div>
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="manageLeaveTypesChecked" />
                        Manage
                      </div>
                    </label>
                  </div>
                  <div class="
                      flex flex-row
                      items-center
                      justify-between
                      gap-5
                      w-[30rem]
                    ">
                    <p class="text-base N700 mb-3">Leave Policies</p>
                    <label class="flex items-center gap-2 pb-2">
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="viewLeavePolicyChecked" />
                        View
                      </div>
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="manageLeavePolicyChecked" />
                        Manage
                      </div>
                    </label>
                  </div>
                  <div class="
                      flex flex-row
                      items-center
                      justify-between
                      gap-5
                      w-[30rem]
                    ">
                    <p class="text-base N700 mb-3">Leave Holidays</p>
                    <label class="flex items-center gap-2 pb-2">
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="viewLeaveHolidaysChecked" />
                        View
                      </div>
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="manageLeaveHolidaysChecked" />
                        Manage
                      </div>
                    </label>
                  </div>
                  <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                    <p class="text-base N700 mb-3">Leave Approvers</p>
                    <label class="flex items-center gap-2 pb-2">
                      <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                        <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewLeaveApproversChecked" />
                        View
                      </div>
                      <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                        <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageLeaveApproversChecked" />
                        Manage
                      </div>
                    </label>
                  </div>
                  <div class="
                      flex flex-row
                      items-center
                      justify-between
                      gap-5
                      w-[30rem]
                    ">
                    <p class="text-base N700 mb-3">Leave Tasks</p>
                    <label class="flex items-center gap-2 pb-2">
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="viewLeaveTasksChecked" />
                        View
                      </div>
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="manageLeaveTasksChecked" />
                        Manage
                      </div>
                    </label>
                  </div>
                  <div class="
                      flex flex-row
                      items-center
                      justify-between
                      gap-5
                      w-[30rem]
                    ">
                    <p class="text-base N700 mb-3">Access Level</p>
                    <label class="flex items-center gap-2 pb-2">
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="viewAccessLevelChecked" />
                        View
                      </div>
                      <div class="
                          flex flex-row
                          items-center
                          N900
                          P200
                          ml-2
                          font-medium
                          flex-nowrap
                        ">
                        <input data-cy="checkbox" type="checkbox" class="
                            mr-2
                            accent-[#dd501d]
                            w-3.5
                            h-3.5
                            hover:cursor-pointer
                          " :style="{ accentColor: '#DD5928' }" v-model="manageAccessLevelChecked" />
                        Manage
                      </div>
                    </label>
                  </div>
                </div>

                <!-- Info -->
                <div class="
                    flex flex-row
                    items-center
                    gap-5
                    overflow-x-auto
                    w-60
                    hide-small
                  ">
                  <div class="
                      flex flex-row
                      items-center
                      gap-2
                      bg-[#fdfdfe]
                      rounded-lg
                      px-2
                    ">
                    <div>
                      <InfoClose />
                    </div>
                    <div class="text-sm N700 py-2">
                      <div>
                        <span class="text-[#DD5928]">View</span> allows user
                        role to view tabs content only.
                      </div>
                      <div>
                        <span class="text-[#DD5928]">Manage</span> allows user
                        role to view & modify the tab’s content.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h3 class="H700 N900">Dashboard</h3>
              <!-- Permissions List -->
              <div class="flex flex-col py-5">
                <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                  <p class="text-base N700 mb-3">Leave Requests</p>
                  <label class="flex items-center gap-2 pb-2">
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewLeaveRequestsChecked" />
                      View
                    </div>
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageLeaveRequestsChecked" />
                      Manage
                    </div>
                  </label>
                </div>
                <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                  <p class="text-base N700 mb-3">Leave Validator</p>
                  <label class="flex items-center gap-2 pb-2">
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewLeaveValidatorChecked" />
                      View
                    </div>
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageLeaveValidatorChecked" />
                      Manage
                    </div>
                  </label>
                </div>
                <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                  <p class="text-base N700 mb-3">Employee Leave Planner</p>
                  <label class="flex items-center gap-2 pb-2">
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewEmployeePlannerChecked" />
                      View
                    </div>
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageEmployeePlannerChecked" />
                      Manage
                    </div>
                  </label>
                </div>
                <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                  <p class="text-base N700 mb-3">Manager Leave Planner</p>
                  <label class="flex items-center gap-2 pb-2">
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewManagerPlannerChecked" />
                      View
                    </div>
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageManagerPlannerChecked" />
                      Manage
                    </div>
                  </label>
                </div>
                <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                  <p class="text-base N700 mb-3">Manager Dashboard</p>
                  <label class="flex items-center gap-2 pb-2">
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewManagerDashboardChecked" />
                      View
                    </div>
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageManagerDashboardChecked" />
                      Manage
                    </div>
                  </label>
                </div>
                <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                  <p class="text-base N700 mb-3">HR Dashboard</p>
                  <label class="flex items-center gap-2 pb-2">
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewHRDashboardChecked" />
                      View
                    </div>
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageHRDashboardChecked" />
                      Manage
                    </div>
                  </label>
                </div>
                <div class="
                    flex flex-row
                    items-center
                    justify-between
                    gap-5
                    w-[30rem]
                  ">
                  <p class="text-base N700 mb-3">HR Employee Information</p>
                  <label class="flex items-center gap-2 pb-2">
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="viewHREmployeeInfoChecked" />
                      View
                    </div>
                    <div class="
                        flex flex-row
                        items-center
                        N900
                        P200
                        ml-2
                        font-medium
                        flex-nowrap
                      ">
                      <input data-cy="checkbox" type="checkbox" class="
                          mr-2
                          accent-[#dd501d]
                          w-3.5
                          h-3.5
                          hover:cursor-pointer
                        " :style="{ accentColor: '#DD5928' }" v-model="manageHREmployeeInfoChecked" />
                      Manage
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="flex items-center justify-end gap-4">
          <ButtonComponent data-cy="cancel" :variant="'secondary'"
            @click="close(), (showSection = 'view-access-levels')" class="O400 rounded-lg">Cancel
          </ButtonComponent>
          <ButtonComponent data-cy="submit" @click="
            editMode
              ? updateLeaveAccessLevels.mutate()
              : addLeaveAccessLevel.mutate()
            " :disabled="roleName.trim().length < 1 || selectedEmployees.length < 1
              " :loading="loading">{{ editMode ? 'Update' : 'Save' }}</ButtonComponent>
        </div>
      </div>
    </div>

    <!-- Employee dialog -->
    <GDialog v-model="employeeListDialog" max-width="30rem"
      :height="filteredUserLists.length < 10 ? 'fit-content' : '40rem'">
      <div class="flex flex-col gap-4 BG0 sticky top-0 px-4 py-4">
        <div class="flex items-center justify-between">
          <h3 class="H600 N900">
            {{ leave.name }} (<span v-if="!filteredUserListsLoading">{{
              filteredUserLists.length
            }}</span>
            <span v-else>{{ filteredUserLists.length }}</span>)
          </h3>
          <CloseCircle @click="employeeListDialog = false" />
        </div>
        <SearchComponent data-cy="search" :clear="clear" class="w-fit" @search="searchUserLists" />
      </div>

      <div v-if="filteredUserListsLoading" class="flex h-[30rem] items-center justify-center">
        <div class="
            border-solid border-t-2 border-[#db5b34]
            rounded-full
            w-40
            h-40
            animate-spin
          "></div>
      </div>
      <div v-if="!filteredUserListsLoading" class="py-2 px-2 h-fit overflow-y-auto">
        <div v-if="!filteredUserLists.length" class="flex h-[30rem] items-center justify-center">
          <span v-if="queryLoading">Please wait...</span> <span v-else>No employees found</span>
        </div>
        <div v-for="user in filteredUserLists" :key="user.id">
          <div class="
              flex flex-row
              items-center
              justify-between
              gap-4
              px-2
            ">
            <span class="
                flex
                items-center
                justify-start
                gap-2
                pt-2
                truncate
                w-fit
                N900
              " :title="user?.full_name">
              <div class="w-[3rem]">
                <ProfileImageComponent :imgSrc="user?.profile_image" :initials="getUserNameInitials(user?.full_name)"
                  widthSize="3rem" heightSize="3rem" textSize="text-2xl" />
              </div>
              <span class="truncate">{{ user?.full_name }}</span>
            </span>
            <span v-if="user.id == currentUserId"></span>
            <ButtonComponent v-else button-label="Remove" @click="handleRemoveUser(user)"
              :variant="'with_border-hover with_border'" dataCy="removeEmployee">
            </ButtonComponent>
          </div>
        </div>
      </div>
    </GDialog>

    <!-- Archive dialog -->
    <GDialog v-model="archiveFormDialog" max-width="29.688rem">
      <ArchiveDialog v-if="leave.is_archived === false" @close-dialog="archiveFormDialog = false"
        :component-title="archiveDialogTitle" :deleteBtn="archiveDialogDeleteBtn" @delete="archive"
        :delete-message="archiveDialogDeleteMessage" />
      <ArchiveDialog v-else @close-dialog="archiveFormDialog = false" :component-title="unarchiveDialogTitle"
        :deleteBtn="unarchiveDialogDeleteBtn" @delete="unarchive" :delete-message="unarchiveDialogDeleteMessage" />
    </GDialog>

    <!-- Remove dialog -->
    <GDialog v-model="removeFormDialog" max-width="29.688rem">
      <ArchiveDialog @close-dialog="removeFormDialog = false" :component-title="removeDialogTitle"
        :deleteBtn="removeDialogDeleteBtn" @delete="remove" :delete-message="removeDialogDeleteMessage" />
    </GDialog>

    <AlertComponent :message-block="messageBlock" />
  </template>
  <div v-else-if="env.VUE_APP_LEAVE_TYPE">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <div class="p-3 mx-auto mt-10"></div>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
// Vue imports
import { reactive, ref, computed, watchEffect, watch } from 'vue';
import { getUserNameInitials } from '@/helpers/functions';
import {
  viewLeaveTypesChecked,
  manageLeaveTypesChecked,
  viewLeavePolicyChecked,
  manageLeavePolicyChecked,
  viewLeaveHolidaysChecked,
  manageLeaveHolidaysChecked,
  viewLeaveTasksChecked,
  manageLeaveTasksChecked,
  viewAccessLevelChecked,
  manageAccessLevelChecked,
  viewLeaveRequestsChecked,
  manageLeaveRequestsChecked,
  viewLeaveApproversChecked,
  manageLeaveApproversChecked,
  viewLeaveValidatorChecked,
  manageLeaveValidatorChecked,
  viewEmployeePlannerChecked,
  manageEmployeePlannerChecked,
  viewManagerPlannerChecked,
  manageManagerPlannerChecked,
  viewManagerDashboardChecked,
  manageManagerDashboardChecked,
  viewHRDashboardChecked,
  manageHRDashboardChecked,
  viewHREmployeeInfoChecked,
  manageHREmployeeInfoChecked,
  permissionNames,
  resetCheckboxes
} from '@/apps/leave-management/helpers/leave-configuration/accessLevels';
import {
  toggleFilters, showFilters, searchTableData, searchedQuery, messageBlock, setLocal, showOnboarding
} from "@/apps/leave-management/helpers/book-leaves/LeaveRequests";

// Interfaces
import { LeaveAccessLevels } from '../../interfaces/leave-configuration/LeaveAccessLevels';

// SVG Component Imports
import CloseCircle from '../../assets/svgComponents/CloseCircle.vue';
import InfoClose from '../../assets/svgComponents/InfoClose.vue';
import EditIcon from '@/assets/EditSvg.vue';
import PersonIcon from '@/assets/PersonSvg.vue';
import ArchiveSvg from '@/assets/ArchiveSvg.vue';
import TrashIcon from '@/assets/TrashSvg.vue';

// UI Component Imports
import ArchiveDialog from '@/ui-kit/DeleteDialog.vue';
import SelectFieldValue from '@/ui-kit/Inputs/SelectField.vue';
import InputFieldValue from '@/ui-kit/Inputs/InputField.vue';
import Multiselect from '@/ui-kit/Inputs/MultiSelectInput.vue';
import ButtonComponent from '@/ui-kit/button/ButtonComponent.vue';
import LoaderIconVue from '@/ui-kit/loader/LoaderIcon.vue';
import TableComponentVue from '@/ui-kit/table/TableComponent.vue';
import AlertComponent from '@/ui-kit/AlertComponent.vue';
import VersionDisplay from '@/components/HelloWorld.vue';
import OnboardingCardComponent from '@/components/OnboardingCardComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import DropdownMenu from '@/components/DropdownMenu.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import HorizontalLine from '@/ui-kit/HorizontalLine.vue';
import ProfileImageComponent from '@/components/ProfileImageComponent.vue';

// Apollo Imports
import {
  useQuery,
  useMutation,
  useMutationLoading,
  useQueryLoading
} from '@vue/apollo-composable';

// GraphQL Mutations and Queries
import {
  CREATE_ACCESS_LEVEL,
  UPDATE_ACCESS_LEVEL,
  ARCHIVE_ACCESS_LEVEL,
  DELETE_ACCESS_LEVEL,
  DELETE_USERS_FROM_ACCESS_LEVEL
} from '../../graphql/mutations/leave-configuration/setAccessLevels';
import { GET_LIST_OF_EMPLOYEES } from '../../graphql/querries/hr-dashboard/getHrApprovals';
import {
  GET_ACCESS_LEVELS,
  GET_ACCESS_LEVEL_USERS
} from '../../graphql/querries/leave-configuration/getAccessLevels';
import { useAccessStore } from '@/apps/leave-management/store/store_leaves_permissions';
import NotAuthorised from '@/components/NotAuthorized.vue';
import { removeActionHeader } from '@/apps/leave-management/store/permissionFunctions';
import { useStore } from '@/store';

// Constants
const env = process.env;

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesSettingsPerm = permissions ?? {};
const currentUserId = ref(useStore().user.user_id);
const clear = ref(false);

const roleName = ref('');
const selectedEmployees = ref('');
const addDialog = ref(false);
const editMode = ref(false);
const employeeListDialog = ref(false);
const archiveFormDialog = ref(false);
const removeFormDialog = ref(false);
const ListAccessLevels = ref<LeaveAccessLevels[]>([]);
const showAlertMessage = ref(true);
const selectedFilter = ref('Unarchived');
const showSection = ref('view-access-levels');
const showContent = ref(false);
const queryLoading = useQueryLoading();
const loading = useMutationLoading();

const headers = [
  { key: 'name', label: 'Role' },
  { key: 'permissions', label: 'Permissions' },
  { key: 'action', label: 'Actions' }
];

const leave: LeaveAccessLevels = reactive<LeaveAccessLevels>({
  id: '',
  name: '',
  permissions: '',
  users: [],
  is_archived: false
});

// computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, leavesSettingsPerm.manage_leave_access_level)
);

const { result: leaveEmployeeResult } = useQuery(GET_LIST_OF_EMPLOYEES);
const leaveemployees = computed<{ id: string; name: string }[]>(() => {
  if (leaveEmployeeResult.value?.listLeaveEmployees) {
    return leaveEmployeeResult.value.listLeaveEmployees
      .filter((item: { id: string; full_name: string | null }) => item.full_name !== '' && item.full_name !== null)
      .map((item: { id: string; full_name: string }) => ({
        id: item.id,
        name: item.full_name
      }));
  } else {
    return [];
  }
});

const filterDataByName = () => {
  return ListAccessLevels.value?.filter((item) => {
    return (
      item?.name &&
      item.name.toLowerCase().includes(searchedQuery.value.toLowerCase())
    );
  }) || [];
};

const filterDataByArchivedStatus = () => {
  return ListAccessLevels.value.filter((item) => {
    if (selectedFilter.value === 'Archived') {
      return item.is_archived;
    } else if (selectedFilter.value === 'Unarchived') {
      return !item.is_archived;
    }
  });
};

const sortDataByName = (data) => {
  return data.sort((a: { name: string; }, b: { name: string; }) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

const tableData = computed(() => {
  if (!ListAccessLevels.value || ListAccessLevels.value.length === 0) {
    return [];
  }

  if (searchedQuery.value.trim() !== '') {
    return filterDataByName();
  } else {
    const filteredData = filterDataByArchivedStatus();
    return sortDataByName(filteredData.slice());
  }
});

// fucntions 
const close = () => {
  resetCheckboxes();
  leave.id = '';
  roleName.value = '';
  selectedEmployees.value = '';
  editMode.value = false;
  addDialog.value = false;
};

watch(addDialog, (value) => {
  clear.value = !!value;
});

function generatePermissions() {
  return {
    settings: {
      approvers: {
        selected: 0,
        enable_all: true,
        view_leave_approvers: viewLeaveApproversChecked.value,
        manage_leave_approvers: manageLeaveApproversChecked.value
      },
      leave_management: {
        selected: 0,
        enable_all: true,
        view_leave_type: viewLeaveTypesChecked.value,
        manage_leave_type: manageLeaveTypesChecked.value,
        view_leave_policy: viewLeavePolicyChecked.value,
        manage_leave_policy: manageLeavePolicyChecked.value,
        view_leave_holidays: viewLeaveHolidaysChecked.value,
        manage_leave_holidays: manageLeaveHolidaysChecked.value,
        view_hr_dashboard: viewHRDashboardChecked.value,
        manage_hr_dashboard: manageHRDashboardChecked.value,
        view_hr_employee_info: viewHREmployeeInfoChecked.value,
        manage_hr_employee_info: manageHREmployeeInfoChecked.value,
        view_manager_dashboard: viewManagerDashboardChecked.value,
        manage_manager_dashboard: manageManagerDashboardChecked.value,
        view_leaves: viewLeaveRequestsChecked.value,
        manage_leaves: manageLeaveRequestsChecked.value,
        view_employee_leave_planner: viewEmployeePlannerChecked.value,
        manage_employee_leave_planner: manageEmployeePlannerChecked.value,
        view_manager_leave_planner: viewManagerPlannerChecked.value,
        manage_manager_leave_planner: manageManagerPlannerChecked.value,
        view_leave_tasks: viewLeaveTasksChecked.value,
        manage_leave_tasks: manageLeaveTasksChecked.value,
        view_leaves_validator: viewLeaveValidatorChecked.value,
        manage_leaves_validator: manageLeaveValidatorChecked.value,
        view_leave_access_level: viewAccessLevelChecked.value,
        manage_leave_access_level: manageAccessLevelChecked.value
      }
    }
  };
}

const handleSelectOption = (item: LeaveAccessLevels, name: string) => {
  if (name === 'Edit') {
    editLeaveAccessLevels(item);
  } else if (name === 'Archive' || name === 'Unarchive') {
    handleArchiveClick(item);
  } else if (name === 'Remove') {
    handleRemoveClick(item);
  } else if (name === 'View') {
    handleViewPeople(item);
  }
};

const handleCreateRoleClick = () => {
  editMode.value = false;
  showSection.value = 'add-edit-role';
  showContent.value = false;

  setTimeout(() => {
    showContent.value = true;
  }, 2000);
};

const handleViewPeople = (item: LeaveAccessLevels) => {
  leave.id = item.id;
  leave.name = item.name;
  employeeListDialog.value = true;
  clear.value = true;
};

const editLeaveAccessLevels = (item: LeaveAccessLevels) => {
  leave.id = item.id;
  roleName.value = item.name;
  selectedEmployees.value = item.users?.map((user) => ({ id: user.id, name: user.full_name })) ?? [];
  editMode.value = true;
  addDialog.value = true;
  showSection.value = 'add-edit-role';
  showContent.value = false;

  setTimeout(() => {
    showContent.value = true;
  }, 2000);

  const { settings } = item.permissions ?? {};
  const { leave_management, approvers } = settings || {};

  viewLeaveApproversChecked.value = approvers?.view_leave_approvers;
  manageLeaveApproversChecked.value = approvers?.manage_leave_approvers;
  viewLeaveTypesChecked.value = leave_management?.view_leave_type;
  manageLeaveTypesChecked.value = leave_management?.manage_leave_type;
  viewLeavePolicyChecked.value = leave_management?.view_leave_policy;
  manageLeavePolicyChecked.value = leave_management?.manage_leave_policy;
  viewLeaveHolidaysChecked.value = leave_management?.view_leave_holidays;
  manageLeaveHolidaysChecked.value = leave_management?.manage_leave_holidays;
  viewLeaveTasksChecked.value = leave_management?.view_leave_tasks;
  manageLeaveTasksChecked.value = leave_management?.manage_leave_tasks;
  viewAccessLevelChecked.value = leave_management?.view_leave_access_level;
  manageAccessLevelChecked.value = leave_management?.manage_leave_access_level;
  viewLeaveValidatorChecked.value = leave_management?.view_leaves_validator;
  manageLeaveValidatorChecked.value = leave_management?.manage_leaves_validator;
  viewHRDashboardChecked.value = leave_management?.view_hr_dashboard;
  manageHRDashboardChecked.value = leave_management?.manage_hr_dashboard;
  viewHREmployeeInfoChecked.value = leave_management?.view_hr_employee_info;
  manageHREmployeeInfoChecked.value = leave_management?.manage_hr_employee_info;
  viewManagerDashboardChecked.value = leave_management?.view_manager_dashboard;
  manageManagerDashboardChecked.value =
    leave_management?.manage_manager_dashboard;
  manageManagerPlannerChecked.value =
    leave_management?.manage_manager_leave_planner;
  viewManagerPlannerChecked.value =
    leave_management?.view_manager_leave_planner;
  viewEmployeePlannerChecked.value =
    leave_management?.view_employee_leave_planner;
  manageEmployeePlannerChecked.value =
    leave_management?.manage_employee_leave_planner;
  viewLeaveRequestsChecked.value = leave_management?.view_leaves;
  manageLeaveRequestsChecked.value = leave_management?.manage_leaves;
};

// Mutations & Queries
const leaveAccessLevels = useQuery(GET_ACCESS_LEVELS);

leaveAccessLevels.onResult((result) => {
  ListAccessLevels.value = result.data?.getAllLeaveAccessLevels || [];
});

const addLeaveAccessLevel = useMutation(CREATE_ACCESS_LEVEL, () => {
  let userIds: string[] = [];
  if (selectedEmployees.value) {
    const selectedEmployeesArray = Array.isArray(selectedEmployees.value)
      ? selectedEmployees.value
      : [selectedEmployees.value];
    userIds = selectedEmployeesArray
      .filter(
        (type): type is { id: string } => typeof type !== 'string' && !!type.id
      )
      .map((type) => type.id);
  }
  return {
    variables: {
      data: {
        name: roleName.value,
        users: userIds,
        permissions: generatePermissions(),
        created_by: useStore().user?.user_id
      }
    }
  };
});

const { refetch: refetchAccessLevels } = useQuery(GET_ACCESS_LEVELS);
addLeaveAccessLevel.onDone(() => {
  refetchAccessLevels();
  close();
  showSection.value = 'view-access-levels';
  showContent.value = false;
  selectedEmployees.value = '';
  messageBlock.open = true;
  messageBlock.severity = 'success';
  messageBlock.message = 'Access level added successfully';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

addLeaveAccessLevel.onError((error) => {
  messageBlock.open = true;
  messageBlock.severity = 'error';
  messageBlock.message =
    error.message.length <= 100
      ? error.message
      : 'Access level creation failed';
  close();
  showSection.value = 'view-access-levels';
  showContent.value = false;
  selectedEmployees.value = '';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const accessLevelId = ref(leave?.id);
const { result: accessLevelUsersResult, refetch: refetchAccessLevelUsers } =
  useQuery(GET_ACCESS_LEVEL_USERS);
const filteredUserLists = computed(() => {
  const searchString = searchedUserListsQuery.value.toLowerCase().trim();
  const filteredUsers = userLists.value?.filter(
    (user) =>
      user?.full_name && user.full_name.toLowerCase().includes(searchString)
  ) || [];
  return filteredUsers
    .filter((user) => user?.full_name !== null)
    .sort((a, b) => a.full_name.localeCompare(b.full_name));
});

const filteredUserListsLoading = ref(true);
const searchedUserListsQuery = ref('');
const userLists = ref([]);

function searchUserLists(searchString: string) {
  searchedUserListsQuery.value = searchString;
}

watch(
  () => leave.id,
  (newLeaveId) => {
    if (newLeaveId) {
      accessLevelId.value = newLeaveId;
      refetchAccessLevelUsers({ accessLevelId: newLeaveId });
    }
  }
);
watchEffect(() => {
  filteredUserListsLoading.value = true;
  userLists.value =
    accessLevelUsersResult.value?.getLeaveAccessLevelById?.users ?? [];
  setTimeout(() => {
    filteredUserListsLoading.value = false;
  }, 50);
});

const updateLeaveAccessLevels = useMutation(UPDATE_ACCESS_LEVEL, () => ({
  variables: {
    leaveTypeId: leave.id,
    data: {
      id: leave.id,
      name: roleName.value,
      users: selectedEmployees.value
        ? selectedEmployees.value.map((user) => user.id)
        : [],
      permissions: generatePermissions(),
      updated_by: useStore().user?.user_id
    }
  }
}));

updateLeaveAccessLevels.onDone(() => {
  refetchAccessLevels();
  messageBlock.open = true;
  messageBlock.severity = 'success';
  close();
  showSection.value = 'view-access-levels';
  showContent.value = false;
  selectedEmployees.value = '';
  messageBlock.message = 'Access level updated successfully';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

updateLeaveAccessLevels.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  messageBlock.severity = 'error';
  messageBlock.message =
    error.message.length <= 100 ? error.message : 'Please contact support';
  close();
  showSection.value = 'view-access-levels';
  showContent.value = false;
  selectedEmployees.value = '';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const removeDialogTitle = ref('');
const removeDialogDeleteBtn = ref('');
const removeDialogDeleteMessage = ref('');

const handleRemoveClick = async (item: LeaveAccessLevels) => {
  leave.id = item.id;
  leave.name = item.name;
  leave.is_archived = item.is_archived;

  removeFormDialog.value = true;
  removeDialogTitle.value = 'Remove Access level';
  removeDialogDeleteMessage.value = `Do you really want to remove ${leave.name}?`;
  removeDialogDeleteBtn.value = 'Remove';
};

const remove_leave_access_level = useMutation(DELETE_ACCESS_LEVEL, () => ({
  variables: {
    accessLevelId: leave.id
  }
}));

const remove = () => {
  remove_leave_access_level.mutate();
};

remove_leave_access_level.onDone(() => {
  refetchAccessLevels();
  messageBlock.open = true;
  messageBlock.severity = 'success';
  removeFormDialog.value = false;
  messageBlock.message = 'Access level removed successfully';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

remove_leave_access_level.onError((error) => {
  messageBlock.open = true;
  removeFormDialog.value = false;
  messageBlock.severity = 'error';
  messageBlock.message =
    error.message.length <= 100 ? error.message : 'Please contact support';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const removeUserFromAccessLevel = useMutation(DELETE_USERS_FROM_ACCESS_LEVEL);
const handleRemoveUser = async (user: { id: [number] }) => {
  try {
    await removeUserFromAccessLevel.mutate({ users: [user.id] });
    messageBlock.open = true;
    messageBlock.severity = 'success';
    messageBlock.message = 'Employee unassigned successfully';
    archiveFormDialog.value = false;
    setTimeout(() => {
      messageBlock.open = false;
    }, 5000);
    refetchAccessLevels();
  } catch (error) {
    messageBlock.open = true;
    messageBlock.severity = 'error';
    archiveFormDialog.value = false;
    messageBlock.message = 'Failed to unassign employee';
    setTimeout(() => {
      messageBlock.open = false;
    }, 5000);
  }
};

const archiveDialogTitle = ref('');
const archiveDialogDeleteBtn = ref('');
const archiveDialogDeleteMessage = ref('');
const unarchiveDialogTitle = ref('');
const unarchiveDialogDeleteBtn = ref('');
const unarchiveDialogDeleteMessage = ref('');

const handleArchiveClick = (item: LeaveAccessLevels) => {
  leave.id = item.id;
  leave.name = item.name;
  leave.is_archived = item.is_archived;

  if (leave.is_archived === false) {
    archiveFormDialog.value = true;
    archiveDialogTitle.value = 'Archive Access level';
    archiveDialogDeleteBtn.value = 'Archive';
    archiveDialogDeleteMessage.value = `Do you really want to archive ${leave.name}?`;
  } else if (leave.is_archived === true) {
    archiveFormDialog.value = true;
    unarchiveDialogTitle.value = 'Unarchive Access level';
    unarchiveDialogDeleteBtn.value = 'Unarchive';
    unarchiveDialogDeleteMessage.value = `Are you sure you want to unarchive ${leave.name}?`;
  }
};

const archive_leave_access_level = useMutation(ARCHIVE_ACCESS_LEVEL, () => ({
  variables: {
    accessLevelId: leave.id,
    isArchived: true
  }
}));

const archive = () => {
  archive_leave_access_level.mutate();
};

archive_leave_access_level.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = 'success';
  archiveFormDialog.value = false;
  messageBlock.message = 'Access level archived successfully';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

archive_leave_access_level.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = 'error';
  messageBlock.message =
    error.message.length <= 100 ? error.message : 'Please contact support';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const unarchive_leave_access_level = useMutation(ARCHIVE_ACCESS_LEVEL, () => ({
  variables: {
    accessLevelId: leave.id,
    isArchived: false
  }
}));

const unarchive = () => {
  unarchive_leave_access_level.mutate();
};

unarchive_leave_access_level.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = 'success';
  archiveFormDialog.value = false;
  messageBlock.message = 'Access level unarchived successfully';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

unarchive_leave_access_level.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = 'error';
  messageBlock.message =
    error.message.length <= 100 ? error.message : 'Please contact support';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});
</script>

<style scoped>
.description {
  position: relative;
}

.tooltip-text {
  max-width: 700px;
  width: max-content;
  position: absolute;
  flex-wrap: wrap;
  background-color: #101840;
  color: #fff;
  border-radius: 8px;
  padding: 8px;
  z-index: 1;
  display: none;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-text span {
  margin-right: 8px;
}

.description:hover .tooltip-text {
  display: flex;
}
</style>