<template>
  <div class="w-full rounded-lg border border-gray-200 BG0"
    :class="{ 'overflow-x-auto': overflowXAuto, 'custom-scrollbar': customScrollbar }">
    <table class="min-w-full leading-normal" aria-describedby="table">
      <thead>
        <tr>
          <template v-for="header in headers" :key="header.key">
            <th class="
    px-5
    py-5
    text-left
    font-sans font-medium
    P250
    tracking-wider
    whitespace-nowrap
    " :style="{ width: getColumnWidths(header) }"
              :class="[header.bgcolor || 'bg-gray-50', 'px-5 py-5 text-left font-sans font-medium P250 tracking-wider whitespace-nowrap']">
              <slot :name="`header-${header.key}`" :header="header">{{ header["label"] }}</slot>
            </th>
          </template>
        </tr>
      </thead>
      <template v-if="isDraggable && (displayedItems.length > 0 || addNewRows)">
        <draggable v-model="dragTableItems" @change="handleTableRowsOnDragChange" tag="tbody" item-key="id"
          :handle="useDragHandler ? '.drag-handle' : ''">
          <transition-group type="animation" name="table-rows">
            <tr v-for="(item, index) in dragTableItems" :key="index" :class="rowStyle" class="hover:bg-gray-100">
              <td v-for="(header, dataIndex) in headers" :key="header.key"
                class="py-2 px-4 border-t border-gray-200 whitespace-nowrap" :class="rowDataClass">
                <slot :name="header.key" :item="item">
                  <span v-if="dataIndex === 0 && useDragHandler" class="
                      drag-handle
                      flex
                      items-center
                      justify-end
                      cursor-grabbing
                    " :class="dragHandleClass">
                    <img :src="dragImgSrc" :class="dragImgClass" alt="Drag icon" />{{
                      item[header.key]
                    }}
                  </span>
                  <span v-else>{{ item[header.key] }}</span>
                </slot>
              </td>
            </tr>
          </transition-group>
        </draggable>

        <tr v-if="addNewRows">
          <td v-for="header in headers" :key="header.key" class="py-2 px-4 border-t border-gray-200 first-letter:">
            <slot :name="`col-${header.key}`"></slot>
          </td>
        </tr>
      </template>
      <template v-else>
        <tbody v-if="displayedItems?.length > 0 || addNewRows">
          <tr v-for="(item, index) in displayedItems" :key="item.id" :class="rowStyle" class="hover:bg-gray-100">
            <td v-for="header in headers" :key="header.key" class="py-2 px-4 border-t border-gray-200 whitespace-nowrap"
              :class="rowDataClass">
              <slot :name="header.key" :item="item" :index="index" :displayedItems="displayedItems.length">{{
                item[header.key]
              }}</slot>
            </td>
          </tr>
          <tr v-if="addNewRows">
            <td v-for="header in headers" :key="header.key" class="py-2 px-4 border-t border-gray-200 first-letter:">
              <slot :name="`col-${header.key}`"></slot>
            </td>
          </tr>
        </tbody>
      </template>
    </table>
    <div v-if="displayedItems?.length === 0 && !addNewRows" class="
        h-[50vh]
        min-w-full
        leading-normal
        flex
        justify-center
        items-center
        py-4
      ">
      <div class="w-full h-fit m-auto text-center">
        <img class="ml-auto mr-auto" src="../../assets/not_found.svg" alt="Not found image" />
        <p class="text-[#8F95B2]">{{ emptyMessage }} :(</p>
      </div>
    </div>
  </div>
  <!-- Pagination Controls -->
  <div v-if="totalPages > 1" class="flex justify-end items-center my-4" :class="{ 'hidden': hiddePagination }">
    <button @click="prevPage" :disabled="currentPage === 1"
      class="hover:bg-[#f9e0d7] text-white font-bold py-3 px-4 rounded-l">
      <img src="../../assets/arrow-left-rounded.svg" alt="" />
    </button>
    <div class="flex space-x-2">
      <button v-for="page in pages" :key="page" @click="changePage(page)" :class="[
        'hover:bg-[#dd5928] py-2 px-4 rounded',
        { 'bg-[#f9e0d7]': currentPage === page },
        { 'text-orange-400': currentPage === page }
      ]">
        {{ page }}
      </button>
    </div>
    <button @click="nextPage" :disabled="currentPage === totalPages"
      class="hover:bg-[#f9e0d7] text-white font-bold py-3 px-4 rounded-r">
      <img src="../../assets/arrow-right-rounded.svg" alt="" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { VueDraggableNext as draggable } from "vue-draggable-next";
import { ref, computed, watch, Component } from "vue";

interface Header {
  key: string;
  label: string;
  classNames?: string,
  icon?: Component;
}

const emit = defineEmits(["tableRowsReordered"]);

const props = defineProps({
  headers: {
    type: Array as () => Array<Header>,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  itemsPerPage: {
    type: Number,
    default: 10 // Set a default value for the number of items per page
  },
  emptyMessage: {
    type: String,
    default: "No data available"
  },
  columnWidths: {
    type: Object as () => Record<string, string>,
    default: () => ({})
  },
  rowStyle: {
    type: String,
    default: ""
  },
  pageRange: {
    type: Number,
    default: 1 // Set a default value for the page range
  },
  className: {
    type: String,
    default: ""
  },
  headerTextColor: {
    type: String,
    default: ""
  },
  rowDataClass: {
    type: String,
    default: ""
  },
  addNewRows: {
    type: Boolean,
    default: false
  },
  overflowXAuto: {
    type: Boolean,
    default: false
  },
  addNewRowsDataClass: {
    type: String,
    default: ""
  },
  isDraggable: {
    type: Boolean,
    default: false
  },
  dragHandleClass: {
    type: String,
    default: ""
  },
  dragImgClass: {
    type: String,
    default: ""
  },
  headerBgColor: {
    type: Array,
    default: () => [{ bgColor: 'bg-gray-50' }],
  },
  dragImgSrc: {
    type: String,
    default: ""
  },
  useDragHandler: {
    type: Boolean,
    default: false
  },
  hiddePagination: {
    type: Boolean,
    default: false,
  },
  customScrollbar: {
    type: Boolean,
    default: false,
  },
});

//set Data for Draggable Table
const dragTableItems = ref<any>([]);

// Data variables for pagination
const currentPage = ref(1);

//set table column widths
const getColumnWidths = (header: Header) => {
  return props.columnWidths[header.key] || "auto";
};

// Compute the total number of pages based on the number of items and items per page
const totalPages = computed(() =>
  Math.ceil(props.items?.length / props.itemsPerPage)
);

// Compute the items to be displayed on the current page
const displayedItems: any = computed(() => {
  const startIndex = (currentPage.value - 1) * props.itemsPerPage;
  const endIndex = startIndex + props.itemsPerPage;
  dragTableItems.value = props.isDraggable
    ? props.items?.slice(startIndex, endIndex)
    : [];
  return props.items?.slice(startIndex, endIndex);
});

// Methods to navigate between pages
function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
  }
}

// Method to handle table row drag changes
function handleTableRowsOnDragChange() {
  emit(
    "tableRowsReordered",
    dragTableItems.value,
    currentPage.value,
    props.itemsPerPage
  );
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1;
  }
}
// Compute an array of page numbers for pagination
const pages = computed(() => {
  const range = [];
  const startPage = Math.max(1, currentPage.value - props.pageRange);
  const endPage = Math.min(
    totalPages.value,
    currentPage.value + props.pageRange
  );

  if (startPage > 1) {
    range.push(1);
    if (startPage > 2) {
      range.push("...");
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }

  if (endPage < totalPages.value) {
    if (endPage < totalPages.value - 1) {
      range.push("...");
    }
    range.push(totalPages.value);
  }

  return range;
});

// Method to change the current page
function changePage(pageNumber: number | string) {
  if (typeof pageNumber === "number") {
    currentPage.value = pageNumber;
  }
}

watch(totalPages, (oldVal, newVal) => {
  if (oldVal !== newVal) {
    currentPage.value = 1;
  }
});
</script>

<style scoped>
/* Style the scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #EDEFF5;
  border-radius: 0 0 7px 7px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #D8DAE5;
}
</style>
