<template>
  <div v-if="label" class="flex justify-between label-div ">
    <label for="Department" class="
        P250
        N800
        flex
        items-center
        mb-2
        my-1
        pt-0.5
        P250
        N800
        dark:text-gray-300
        whitespace-nowrap
      ">{{ label }}</label>
    <span v-if="requireTag" class="
        bg-red-100
        requiredSpan
        rounded-lg
        text-[#DD5928] text-xs
        px-1.5
        py-1
        dark:bg-red-200 dark:text-red-900
        self-center
      ">Required</span>
  </div>
  <div class="flex items-center flex-row-reverse gap-4">
    <multiselect class="
        custom-multiselect
        outline-[#D8DAE5]
        mt-2
        bg-gray-50
        border
        N900
        rounded-lg
        peer
        block
        dark:bg-gray-700 dark:border-gray-600
        placeholder-[#8F95B2]
        dark:placeholder-gray-400
        dark:text-white
        dark:focus:ring-[#F2BEAB]
        dark:focus:border[#d8dae5]
        p-2
        max-h-40
        w-full
        overflow-y-auto overflow-x-hidden
        cursor-pointer
      " :class="{ 'max-h-[400px]': increaseHeight }" v-model="internalValue" :options="options" :multiple="multiple" :close-on-select="closeOnSelect"
      :blockKeys="blockKeys" :groupSelect="groupSelect" :clear-on-select="clearOnSelect" :preserve-search="preserveSearch"
      :hide-selected="hideSelected" :taggable="taggable" :placeholder="placeholder" :trackBy="trackBy" :label="'name'"
      :required="requireTag">
      <template #option="{ option }">
        <div class="
            flex
            items-center
            cursor-pointer
            p-2
            pl-[0.8rem]
            border-l-4 border-transparent
            hover:border-[#DD5928] hover:bg-[#F9E0D7] hover:text-[#DD5928]
            -ml-2
            !w-[29rem]
          ">
          <label class="ml-2 cursor-pointer w-full">{{ option[param] }}</label>
        </div>
      </template>
      <template #tag="{ option, remove }">
        <div class="inline-flex mx-1">
          <div class="
              items-center
              px-2
              py-1
              my-1
              text-sm
              font-medium
              N800
              bg-gray-200
              rounded-lg
            ">
            {{ option[param] }}
          </div>
          <button type="button" class="flex-shrink-0" @click="remove(option)">
            <span class="sr-only">Remove {{ option[param] }}</span>
            <img src="../../assets/close-circle.svg" alt="" srcset="" />
          </button>
        </div>
      </template>
    </multiselect>
    <div v-if="showSelectAll" class="mt-2" title="Select All">
      <input type="checkbox" v-model="selectAll" @change="toggleSelectAll" class="h-5 w-5 cursor-pointer mt-2"
        :style="{ accentColor: '#DD5928' }" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch, defineEmits } from 'vue';
import Multiselect from 'vue-multiselect';
import { useRoute } from 'vue-router';

const currentRoute = useRoute().path;
const increaseHeight = currentRoute === '/leave-approvers' || currentRoute === '/access-level-view';

const props = defineProps({
  modelValue: {
    type: Array,
    default: []
  },
  options: {
    type: Array,
    required: true
  },
  multiple: {
    type: Boolean,
    default: true
  },
  closeOnSelect: {
    type: Boolean,
    default: false
  },
  blockKeys: {
    type: Array,
    default: () => ['Delete']
  },
  groupSelect: {
    type: Boolean,
    default: true
  },
  clearOnSelect: {
    type: Boolean,
    default: false
  },
  preserveSearch: {
    type: Boolean,
    default: true
  },
  hideSelected: {
    type: Boolean,
    default: true
  },
  taggable: {
    type: Boolean,
    default: true
  },
  param: {
    type: String,
    default: 'name'
  },
  trackBy: {
    type: String,
    default: 'id'
  },
  label: {
    type: String,
    default: 'name'
  },
  requireTag: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Select Option'
  },
  showSelectAll: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);
const internalValue = ref<Array<unknown>>(props.modelValue || []);
const selectAll = ref(false);

watch(internalValue, (newValue) => {
  emit('update:modelValue', newValue);
});

watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);

const toggleSelectAll = () => {
  if (selectAll.value) {
    if (props.options && props.options.length > 0) {
      internalValue.value = props.options.map((option: any) => option);
    }
  } else {
    internalValue.value = [];
  }
};
</script>
<style>
.custom-multiselect .multiselect__input {
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.25rem;
  outline-color: #d8dae5;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
</style>
