import { reactive, ref } from "vue";
import { LeaveHrApproval } from "../../interfaces/hr-dashboard/LeaveHrApprovals";
import { RequestStatus } from "../../helpers/leaveHelperFunctions";

export const leave: LeaveHrApproval = reactive<LeaveHrApproval>({
  id: "",
  user: {
    full_name: "",
    profile_image: "",
  },
  country: "",
  user_id: "",
  leave_type_id: "",
  leave_type: "",
  full_name: "",
  profile_image: "",
  description: "",
  document: "",
  days: "",
  days_off: 0,
  carry_over_used: 0,
  first_last_days_start: "",
  first_last_days_end: "",
  first_last_days: "",
  status: "",
  manager_approved: "",
  comments: "",
  file: "",
  is_archived: false,
  touch_point_comments: [],
});

export const statusClass = (status: string) => {
  switch (status) {
    case RequestStatus.APPROVED:
      return "text-[#10899E] bg-[#D3F5F7]";
    case RequestStatus.PENDING:
      return "text-[#b48429] bg-[#fcefd6]";
    case RequestStatus.DENIED:
      return "text-[#b42f2f] bg-[#f4c4c4]";
    default:
      return "";
  }
};

export const statusText = (status: string) => {
  switch (status) {
    case RequestStatus.APPROVED:
      return "Approved";
    case RequestStatus.PENDING:
      return "Pending";
    case RequestStatus.DENIED:
      return "Disapproved";
    default:
      return "";
  }
};

export const isChatVisible = ref(false);

export const toggleChat = () => {
  isChatVisible.value = !isChatVisible.value;
};

export const getFileName = (url: string) => {
  if (url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }
  return "";
};

export const downloadFile = () => {
  const fileUrl = leave.file;
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", "");
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const diabaledDate = () => {
  return true;
};

export const formatLeaveDays = (days: number) => {
  return Number.isInteger(days) ? days.toFixed(0) : days.toFixed(2);
};

type User = {
  full_name: string | null | undefined;
};

type Employee = {
  user: User;
  on_leave: boolean;
};

export const getFilteredByQuery = (
  items: Employee[],
  query: string
): Employee[] => {
  return items.filter((item) => {
    const fullName = item?.user?.full_name ?? "";
    return fullName.toLowerCase().includes(query.toLowerCase());
  });
};

export const getFilteredByLeaveStatus = (
  items: Employee[],
  onLeave: boolean
): Employee[] => {
  return items.filter((item) => (onLeave ? item.on_leave : !item.on_leave));
};

export const getValidNames = (items: Employee[]): Employee[] => {
  return items.filter(
    (item) =>
      item.user?.full_name !== null &&
      item.user?.full_name !== undefined &&
      item.user?.full_name !== ""
  );
};

export const sortByFullName = (items: Employee[]): Employee[] => {
  return items.sort((a, b) => {
    const titleA = (a.user?.full_name ?? "").toLowerCase();
    const titleB = (b.user?.full_name ?? "").toLowerCase();

    if (!titleA) return 1;
    if (!titleB) return -1;
    if (titleA < titleB) return -1;
    if (titleA > titleB) return 1;
    return 0;
  });
};
