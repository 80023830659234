<template>
  <!-- If the type prop is 'button' following template will render -->
  <ul id="componentContainer" :class="paginationContainerClass">
    <!-- Go back to first page Button -->
    <li v-if="showEndingButtons && firstPageButtonIfCondition">
      <component :is="type === 'button' ? 'button' : 'a'" @click.prevent="onClickHandler(isRtl ? totalPages : 1)"
        :class="[
          firstPageButtonClass,
          paginateButtonsClass,
          disablePagination ? disabledPaginateButtonsClass : ''
        ]" :disabled="disablePagination">
        <slot name="first-page-button">
          {{ firstPageContent }}
        </slot>
      </component>
    </li>

    <!-- Back Button -->
    <li v-if="!hidePrevNext && backButtonIfCondition">
      <component :is="type === 'button' ? 'button' : 'a'" @click.prevent="
        onClickHandler(isRtl ? currentPageRef + 1 : currentPageRef - 1)
        " :class="[
          backButtonClass,
          paginateButtonsClass,
          disablePagination ? disabledPaginateButtonsClass : '',
          disablePagination ? disabledBackButtonClass : ''
        ]" :disabled="disablePagination">
        <slot name="prev-button">
          <img src="../../assets/arrow-left-rounded.svg" alt="" />
        </slot>
      </component>
    </li>

    <!-- First Button before Starting Breakpoint Button -->
    <li v-if="showBreakpointButtons && firstButtonIfCondition">
      <component :is="type === 'button' ? 'button' : 'a'" @click.prevent="onClickHandler(isRtl ? totalPages : 1)"
        :class="[
          firstButtonClass,
          paginateButtonsClass,
          disablePagination ? disabledPaginateButtonsClass : '',
          disablePagination ? disabledFirstButtonClass : ''
        ]" :disabled="disablePagination">
        {{ isRtl ? NumbersLocale(totalPages) : NumbersLocale(1) }}
      </component>
    </li>

    <!-- Starting Breakpoint Button -->

    <!-- Numbers Buttons -->
    <li v-for="(page, index) in paginate.pages" :key="index">
      <component :is="type === 'button' ? 'button' : 'a'" @click.prevent="() => onClickHandler(page)" :class="[
        paginateButtonsClass,
        numberButtonsClass,
        page === currentPageRef ? activePageClass : '',
        disablePagination ? disabledPaginateButtonsClass : ''
      ]" :disabled="disablePagination">
        {{ NumbersLocale(page) }}
      </component>
    </li>

    <!-- Ending Breakpoint Button -->
    <li v-if="showBreakpointButtons && endingBreakPointButtonIfCondition">
      <component :is="type === 'button' ? 'button' : 'a'" @click.prevent="
        onClickHandler(
          disableBreakpointButtons
            ? currentPageRef
            : isRtl
              ? currentPageRef - Math.ceil(maxPagesShown / 2)
              : currentPageRef + Math.ceil(maxPagesShown / 2)
        )
        " :disabled="disableBreakpointButtons || disablePagination" :class="[
          endingBreakPointButtonClass,
          paginateButtonsClass,
          disableBreakpointButtons || disablePagination
            ? `${disabledPaginateButtonsClass} ${disabledBreakPointButtonClass}`
            : ''
        ]">
        <slot name="ending-breakpoint-button">
          {{ endingBreakpointButtonContent }}
        </slot>
      </component>
    </li>

    <!-- Last Button after Ending Breakingpoint Button-->
    <li v-if="showBreakpointButtons && lastButtonIfCondition">
      <component :is="type === 'button' ? 'button' : 'a'" @click.prevent="onClickHandler(isRtl ? 1 : totalPages)"
        :class="[
          lastButtonClass,
          paginateButtonsClass,
          disablePagination ? disabledPaginateButtonsClass : '',
          disablePagination ? disabledLastButtonClass : ''
        ]" :disabled="disablePagination">
        {{ isRtl ? NumbersLocale(1) : NumbersLocale(totalPages) }}
      </component>
    </li>

    <!-- Next Button -->
    <li v-if="!hidePrevNext && nextButtonIfCondition">
      <component :is="type === 'button' ? 'button' : 'a'" @click.prevent="
        onClickHandler(isRtl ? currentPageRef - 1 : currentPageRef + 1)
        " :class="[
          paginateButtonsClass,
          nextButtonClass,
          disablePagination ? disabledPaginateButtonsClass : '',
          disablePagination ? disabledNextButtonClass : ''
        ]" :disabled="disablePagination">
        <slot name="next-button">
          <img src="../../assets/arrow-right-rounded.svg" alt="" />
        </slot>
      </component>
    </li>

    <!-- Forward Jump Button -->
  </ul>
</template>
<script setup lang="ts">
import { toRef, computed, type PropType } from "vue";

// component props
const props = defineProps({
  // Configuration props
  totalItems: {
    type: Number,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    default: 10,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
  modelValue: {
    type: Number,
    required: true,
  },
  maxPagesShown: {
    type: Number,
    default: 5,
  },
  dir: {
    type: String as PropType<"ltr" | "rtl">,
    default: "ltr",
  },
  type: {
    type: String as PropType<"link" | "button">,
    default: "button",
  },
  onClick: {
    type: Function,

  },
  locale: {
    type: String as PropType<"en" | "ar" | "ir">,
    default: "en",
  },
  prevButtonContent: {
    type: String,
    default: "<",
  },
  nextButtonContent: {
    type: String,
    default: ">",
  },
  hidePrevNext: {
    type: Boolean,
    default: false,
  },
  hidePrevNextWhenEnds: {
    type: Boolean,
    default: false,
  },
  showBreakpointButtons: {
    type: Boolean,
    default: true,
  },
  disableBreakpointButtons: {
    type: Boolean,
    default: false,
  },
  startingBreakpointContent: {
    type: String,
    default: "...",
  },
  endingBreakpointButtonContent: {
    type: String,
    default: "...",
  },
  showJumpButtons: {
    type: Boolean,
    default: false,
  },
  disablePagination: {
    type: Boolean,
    default: false,
  },
  showEndingButtons: {
    type: Boolean,
    default: false,
  },
  firstPageContent: {
    type: String,
    default: "First",
  },
  lastPageContent: {
    type: String,
    default: "Last",
  },

  // Class props
  backButtonClass: {
    type: String,
    default: "back-button",
  },
  nextButtonClass: {
    type: String,
    default: "next-button",
  },
  firstButtonClass: {
    type: String,
    default: "first-button",
  },
  lastButtonClass: {
    type: String,
    default: "last-button",
  },
  numberButtonsClass: {
    type: String,
    default: "number-buttons",
  },
  startingBreakpointButtonClass: {
    type: String,
    default: "starting-breakpoint-button",
  },
  endingBreakPointButtonClass: {
    type: String,
    default: "ending-breakpoint-button",
  },
  firstPageButtonClass: {
    type: String,
    default: "first-page-button",
  },
  lastPageButtonClass: {
    type: String,
    default: "last-page-button",
  },

  // use this selector above all the other selectors because of css specificity
  paginateButtonsClass: {
    type: String,
    default: "paginate-buttons",
  },
  disabledPaginateButtonsClass: {
    type: String,
    default: "disabled-paginate-buttons",
  },
  activePageClass: {
    type: String,
    default: "active-page",
  },
  paginationContainerClass: {
    type: String,
    default: "pagination-container",
  },
  disabledBreakPointButtonClass: {
    type: String,
    default: "disabled-breakpoint-button",
  },
  backwardJumpButtonClass: {
    type: String,
    default: "backward-jump-button",
  },
  forwardJumpButtonClass: {
    type: String,
    default: "forward-jump-button",
  },
  disabledBackwardJumpButtonClass: {
    type: String,
    default: "disabled-backward-jump-button",
  },
  disabledBackButtonClass: {
    type: String,
    default: "disabled-back-button",
  },
  disabledFirstButtonClass: {
    type: String,
    default: "disabled-first-button",
  },
  disabledLastButtonClass: {
    type: String,
    default: "disabled-last-button",
  },
  disabledNextButtonClass: {
    type: String,
    default: "disabled-next-button",
  },
});


const currentPageRef = toRef(props, "modelValue");

const emit = defineEmits(["update:modelValue"]);

const onClickHandler = (number: number) => {
  if (number === currentPageRef.value) return;

  if (number > totalPages.value) return;

  if (number < 1) return;
  if (props.disablePagination) return;

  emit("update:modelValue", number);
  props.onClick(number);
};
const NumbersLocale = (number: number) => {
  switch (props.locale) {
    case "en":
      return number;
    case "ar":
      return number.toLocaleString("ar-SA");
    case "ir":
      return number.toLocaleString("fa-IR");
    default:
      return number;
  }
};
const navigationHandler = (page: number) => {
  if (props.type !== "link") return "";
  return props.linkUrl.replace("[page]", page.toString());
};

const totalPages = computed(() =>
  Math.ceil(props.totalItems / props.itemsPerPage)
);
// Pagination logic
const paginate = computed(() => {
  let startPage: number, endPage: number;

  if (totalPages.value <= props.maxPagesShown) {
    startPage = 1;
    endPage = totalPages.value;
  } else {

    let maxPagesShownBeforeCurrentPage = Math.floor(props.maxPagesShown / 2);
    let maxPagesShownAfterCurrentPage = Math.ceil(props.maxPagesShown / 2) - 1;
    if (currentPageRef.value <= maxPagesShownBeforeCurrentPage) {
      // current page is at the start of the pagination
      startPage = 1;
      endPage = props.maxPagesShown;
    } else if (
      currentPageRef.value + maxPagesShownAfterCurrentPage >=
      totalPages.value
    ) {
      // current page is at the end of the pagination
      startPage = totalPages.value - props.maxPagesShown + 1;
      endPage = totalPages.value;
    } else {
      // current page is somewhere in the middle of the pagination
      startPage = currentPageRef.value - maxPagesShownBeforeCurrentPage;
      endPage = currentPageRef.value + maxPagesShownAfterCurrentPage;
    }
  }
  // create an array of pages to be displayed
  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  );

  if (props.dir === "rtl") {
    pages = pages.reverse();
  }

  return {
    totalItems: props.totalItems,
    currentPage: currentPageRef.value,
    itemsPerPage: props.itemsPerPage,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    pages: pages,
  };
});
// rtl check
const isRtl = computed(() => props.dir === "rtl");
const backButtonIfCondition = computed(() => {
  if (isRtl.value)
    return (
      !props.hidePrevNextWhenEnds || currentPageRef.value !== totalPages.value
    );

  return !props.hidePrevNextWhenEnds || currentPageRef.value !== 1;
});
const nextButtonIfCondition = computed(() => {
  if (isRtl.value)
    return !props.hidePrevNextWhenEnds || currentPageRef.value !== 1;

  return (
    !props.hidePrevNextWhenEnds || currentPageRef.value !== totalPages.value
  );
});
const endingBreakPointButtonIfCondition = computed(() => {
  if (isRtl.value) {
    return paginate.value.pages[paginate.value.pages.length - 1] >= 3;
  }

  return (
    paginate.value.pages[paginate.value.pages.length - 1] < totalPages.value - 1
  );
});
const firstButtonIfCondition = computed(() => {
  if (isRtl.value) {
    return paginate.value.pages[0] < totalPages.value;
  }

  return paginate.value.pages[0] >= 2;
});
const lastButtonIfCondition = computed(() => {
  if (isRtl.value) {
    return paginate.value.pages[paginate.value.pages.length - 1] >= 2;
  }

  return (
    paginate.value.pages[paginate.value.pages.length - 1] < totalPages.value
  );
});
const firstPageButtonIfCondition = computed(() => {
  if (currentPageRef.value === 1) return false;
  return true;
});
</script>
<style scoped>
ul#componentContainer {
  /* resetting default stylings for ul tag */
  padding-inline-start: 0;
  list-style-type: none;
  display: inline-flex;
}

ul#componentContainer a {
  /* resetting default stylings for a tag */
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  outline-width: 0;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}

.paginate-buttons {
  height: 32px;
  width: 32px;
  border-radius: 8px !important;
  cursor: pointer;
  background-color: white !important;
  border: none !important;
  color: #696f8c !important;
}

.paginate-buttons:hover {
  background-color: transparent;
}

.active-page {
  color: #dd5928 !important;
  outline-width: 0;
  border-radius: 8px !important;
  border: none !important;
  background-color: #f2beab !important;
}

.active-page:hover {
  background-color: white;
}
</style>
