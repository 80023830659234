<template>
  <div class="flex gap-4 items-center justify-between pb-3 pt-3">
    <div v-for="(step, index) in steps" :key="step.number" class="flex items-center justify-center gap-3">
      <div @click="$emit('onStepClick', step.number)" class="flex items-center justify-center gap-1 cursor-pointer">
        <span :class="stepClass(step.number)">
          {{ step.isOK ? "✔" : step.number }}
        </span>
        <span>{{ step.text }}</span>
      </div>
      <!-- Update the separator's class based on the number of separators -->
      <div v-if="separator && index < steps.length - 1" :class="{
        'border-b-2 border-red-300 bg-red-300': separator > 1,
        'border-b-2 border-gray-300 bg-gray-300': separator === 1,
      }" class="w-[12vw] lg:w-[20vw]" style="height: 2px"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface StepInterface {
  number: number;
  text: string;
  isOK: boolean;
}

const props = defineProps({
  currentStep: {
    type: Number,
    required: true,
  },
  steps: {
    type: Array as () => StepInterface[],
    required: true,
  },
  separator: {
    type: Number,
    default: 1,
  },
});

const stepClass = (stepNumber: number) => {
  return {
    "bg-[#DD5928] text-white rounded-full min-w-[26px] h-[24px] flex items-center justify-center hover:bg-orange-500":
      props.currentStep == stepNumber,
    "border-[#DD5928] border O400 hover:text-red-500 rounded-full min-w-[26px] h-[24px] flex items-center justify-center hover:border-orange-600":
      props.currentStep !== stepNumber,
  };
};

const divideWidth = computed(() => {
  const totalSteps = props.steps.length;
  return `${(1 / totalSteps) * 75}%`;
});
</script>
