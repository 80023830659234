<template>
  <GDialog v-model="calculatorDialog" max-width="30rem">
    <div class="p-4">
      <div class="flex items-center justify-between">
        <h3 class="H600 N900">Leave Calculator</h3>
        <CloseCircle @click="$emit('closeDialog')" />
      </div>

      <div class="my-4">
        <SelectFieldValue
          id="leaveTypes"
          :showSearch="false"
          :showLabel="true"
          class="BG0"
          label="Leave types"
          placeholder="Choose a leave type"
          data-cy="leaveTypes"
          :options="leavetypes.map((type) => type.title)"
          v-model="selectedLeaveType"
        />
      </div>
      <div class="my-4">
        <InputFieldValue
          class="BG0"
          label="Dates"
          type="Date"
          data-cy="dates"
          :showlength="false"
          v-model="selectedDate"
          :min="tomorrow"
        />
      </div>

      <div class="my-4">
        <h4 class="block my-2 pt-1 P250 N800 N700">Accrued days</h4>
        <div class="border border-gray-300 p-2 rounded-lg">
          <p class="N700">
            Total:
            <span v-if="!queryLoading"
              >{{
                typeof ListLeaveCalculator === "number"
                  ? ListLeaveCalculator.toFixed(2)
                  : "0.00"
              }}
              days</span
            >
            <span v-else>Loading...</span>
          </p>
        </div>
        <div v-if="error" class="text-red-500">
          {{ error }}
        </div>
      </div>
      <!-- Dialog action -->
      <div class="flex flex-col gap-4 justify-end pt-2">
        <div class="flex items-center justify-end gap-5">
          <ButtonComponent
            data-cy="ok"
            @click="
              () => {
                $emit('closeDialog');
              }
            "
          >
            Ok
          </ButtonComponent>
        </div>
      </div>
    </div>
  </GDialog>
</template>

<script setup lang="ts">
import { ref, toRefs, computed, watch } from "vue";
import { getCurrentDate } from "../../helpers/leaveHelperFunctions";
import CloseCircle from "@/apps/leave-management/assets/svgComponents/CloseCircle.vue";
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import SelectFieldValue from "@/ui-kit/Inputs/SelectField.vue";
import InputFieldValue from "@/ui-kit/Inputs/InputField.vue";
import { useQuery, useQueryLoading } from "@vue/apollo-composable";
import { EMPLOYEE_LEAVE_CALCULATOR } from "@/apps/leave-management/graphql/querries/book-leaves/getLeaveRequests";
import { GET_ALL_LEAVE_TYPES } from "../../graphql/querries/leave-configuration/getLeaveTypes";

const props = defineProps({
  employeeId: String,
});
const { employeeId } = toRefs(props);
let queryLoading = useQueryLoading();

const calculatorDialog = ref(false);
const selectedLeaveType = ref("Annual Leave");
const today = new Date();
today.setDate(today.getDate() + 1);
const tomorrow = today.toISOString().split("T")[0];
const selectedDate = ref(new Date().toISOString().split("T")[0]);

const { result: leaveTypesResult } = useQuery(GET_ALL_LEAVE_TYPES);

const leavetypes = computed<{ id: string; title: string }[]>(() => {
  if (leaveTypesResult.value?.listLeaveTypes) {
    return leaveTypesResult.value.listLeaveTypes
      .filter(
        (item: { id: string; title: string; is_archived: boolean }) =>
          !item.is_archived && item.title === "Annual Leave"
      )
      .map((item: { id: string; title: string }) => ({
        id: item.id,
        title: item.title,
      }));
  } else {
    return [];
  }
});

let leaveCalculator = useQuery(EMPLOYEE_LEAVE_CALCULATOR, {
  date: selectedDate.value || getCurrentDate(),
  userId: employeeId?.value,
});

const error = ref<string | null>(null);

watch(
  () => selectedDate.value,
  async () => {
    try {
      await leaveCalculator.refetch({
        date: selectedDate.value ?? getCurrentDate(),
        userId: employeeId?.value ?? "",
      });

      error.value = null;
    } catch (e) {
      if (e instanceof Error) {
        error.value = e.message || "An error occurred.";
      } else {
        error.value = "An unknown error occurred.";
      }

      setTimeout(() => {
        error.value = null;
      }, 5000);
    }
  }
);

const ListLeaveCalculator = ref<number | number[]>([]);
leaveCalculator.onResult((result) => {
  if (result.data?.leaveCalculator) {
    ListLeaveCalculator.value = result.data.leaveCalculator;
  }
});
</script>
