<template>
  <div class="hello  BG0 px-4 pb-4 rounded-lg">
    <div class="flex flex-col items-center">
      <div class="authorized-animated-svg">
        <img class="!w-full !h-full" :src="require('@/assets/Frame.svg')" alt="notAuthorized" />
      </div>
      <div class="user_info mt-5 p-5 sm:p-10 max-w-[70%]">
        <h2 class="N800">Restricted Access</h2>
        <p class="P200 N800 H800 text-center text-base ">
          You do not have permission to access this resource. Please contact the administrator if you believe this is an
          error
        </p>
      </div>
      <div class="mx-auto" @click="redirect('/home')">
        <ButtonComponentVue text="Go back home" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonComponentVue from "@/ui-kit/button/ButtonComponent.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const redirect = (redirectRoute: string) => router.push({ path: redirectRoute });

</script>

<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

.user_info {
  text-align: center;
}

img {
  margin: auto;
}

@media screen and (min-width: 768px) {
  .authorized-animated-svg {
    width: 500px;
  }
}

@media screen and (max-width: 670px) {
  .authorized-animated-svg {
    width: 300px;
  }
}
</style>