import { defineStore } from "pinia";
import { Permissions } from "@/apps/leave-management/interfaces/leavesPermissionsType";

export const useAccessStore = defineStore({
  id: "leavesPermissions",
  state: () => ({
    permissions: {} as Permissions,
  }),
  getters: {},
  actions: {
    setPermission(permissions: Permissions) {
      this.permissions = permissions;
    },
  },
  persist: true,
});
