<template>
  <div class="N700 flex justify-end px-4 pt-5 content-center" @mouseleave="removeDropdowns">
    <div class="flex relative gap-4">
      <div class="
            flex
            gap-2
            self-center
            cursor-pointer
            bg-[#D8DAE5]
            hover:bg-[#bbbbd0]
            p-2
            h-fit
            w-fit
            rounded-lg
            text-[#2F365A]
          " @click="showAppsDropdown = !showAppsDropdown">
        <img src="../assets/menu-icons/apps.svg" class="appsIcon" alt="appsIcon" /> Apps
      </div>
      <div class="
          self-center
          cursor-pointer
          bg-[#D8DAE5]
          hover:bg-[#bbbbd0]
          p-2
          rounded-lg
          flex
          gap-2
        " @click="redirectToHelp">
        <TooltipComponent :text="'Help'" :position="'bottom'">
          <HelpSvg />
        </TooltipComponent>
      </div>
      <div class="flex gap-4 cursor-pointer" @click="showProfiledropdown = !showProfiledropdown">
        <ProfileImageComponent :initials="getUserNameInitials(username)" :imgSrc="profilePicture" class="rounded-full"
          widthSize="3rem" heightSize="3rem" text-size="text-2xl" />
        <p class="self-center N800 username">{{ username }}</p>
        <img src="@/assets/arrow-down.svg" class="cursor-pointer h-fit self-center" alt="arrow-down" />
      </div>
      <ProfileDropdown v-if="showProfiledropdown" />
      <AppsNavigation v-if="showAppsDropdown" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from '@/store/index';
import ProfileDropdown from './ProfileDropdown.vue';
import AppsNavigation from './AppsNavigation.vue';
import ProfileImageComponent from '@/components/ProfileImageComponent.vue';
import { getUserNameInitials } from '@/helpers/functions';
import { useRouter } from "vue-router";
import HelpSvg from "@/apps/leave-management/assets/svgComponents/HelpSvg.vue";
import TooltipComponent from "@/ui-kit/Tooltip/TooltipComponent.vue";

const store = useStore();
const route = useRouter();
const profilePicture = ref(store.user.profile_image);
const username = computed(() => store.user.first_name);
store.$subscribe((_, state) => {
  profilePicture.value = state.user.profile_image;
})
const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);
const removeDropdowns = () => {
  showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};

const redirectToHelp = () => {
  route.push("/leave-assisstance");
};
</script>
<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }

  .username {
    display: none;
  }
}
</style>
