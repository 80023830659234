import axios from "axios";
import { ref } from "vue";
import {
  LeaveManagerApproval,
  LeaveManagerPlanner,
} from "../interfaces/manager-dashboard/LeaveManagerApprovals";
import { LeaveRequest } from "../interfaces/book-leaves/LeaveRequests";
import { LeaveHrApproval } from "../interfaces/hr-dashboard/LeaveHrApprovals";

// Date Function
export const formatDateCorrectly = (timestamp: string) => {
  const datePart = timestamp.split("T")[0];
  const date = new Date(datePart);

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
};

export const getDayOfWeek = (timestamp: string) => {
  const datePart = timestamp.split("T")[0];
  const date = new Date(datePart);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return daysOfWeek[date.getDay()];
};
export const getYearFromDate: (dateString: string) => number = (
  dateString: string
) => {
  const date = new Date(dateString);
  return date.getFullYear();
};

export const formatDateApi = (dateString: string) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
};

export const formatDateShort = (dateString: string) => {
  const startDate = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const formattedStartDate = startDate.toLocaleDateString("en-US", options);

  return `${formattedStartDate}`;
};

export const getDayOfWeekApi = (dateString: string) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const date = new Date(dateString);
  return daysOfWeek[date.getDay()];
};

export const padZero = (num: number) => (num < 10 ? `0${num}` : num);
export const formatDatePicker = (date: Date) => {
  const day = padZero(date.getUTCDate());
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  return `${day} ${month} ${year}`;
};

export const formatDate = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = padZero(date.getUTCMonth() + 1);
  const day = padZero(date.getUTCDate());
  const hours = padZero(date.getUTCHours());
  const minutes = padZero(date.getUTCMinutes());
  const seconds = padZero(date.getUTCSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// Years and Countries
export function generateYears(startYear: string): number[] {
  const years = [];
  const start = parseInt(startYear, 10);

  if (!isNaN(start)) {
    for (let year = start; year <= start + 50; year++) {
      years.push(year);
    }
  }

  // Sort the years in ascending order
  years.sort((a, b) => a - b);

  return years;
}

type CountryData = {
  name: {
    common: string;
  };
};

export async function generateListOfCountries() {
  try {
    const response = await axios.get("https://restcountries.com/v3.1/all");
    const countries = response.data.map(
      (country: CountryData) => country.name.common
    );
    // Sort the countries array alphabetically
    countries.sort((a: string, b: string) => a.localeCompare(b));
    return countries;
  } catch (error) {
    return [];
  }
}

// Filters
export enum SelectedFilter {
  ALL = "All",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export enum RequestStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  DENIED = "DENIED",
}

export const createStatusRef = (initialStatus: RequestStatus) => {
  return ref<RequestStatus>(initialStatus);
};

export const updateStatus = (
  status: RequestStatus,
  requestStatus: { value: RequestStatus }
) => {
  requestStatus.value = status;
};

export const filterByManagerApproved = (
  list: LeaveManagerApproval[],
  manager_approved: RequestStatus
): LeaveManagerApproval[] => {
  return list.filter((item) => item.manager_approved === manager_approved);
};

export const filterByHrApproved = (
  list: LeaveHrApproval[],
  status: RequestStatus
): LeaveHrApproval[] => {
  return list.filter((item) => item.status === status);
};

export const filterByApproved = (
  list: LeaveRequest[],
  status: SelectedFilter
): LeaveRequest[] => {
  if (status === SelectedFilter.ALL) {
    return list;
  } else if (status === SelectedFilter.PENDING) {
    return list.filter(
      (item) =>
        item?.status === status?.toUpperCase() &&
        (item?.manager_approved === SelectedFilter.PENDING ||
          item?.manager_approved === undefined)
    );
  } else {
    return list.filter(
      (item) =>
        item?.status === status?.toUpperCase() &&
        item?.manager_approved === status?.toUpperCase()
    );
  }
};

export const updateRequestTotal = (
  listLeaveTypes: LeaveManagerApproval[],
  total: Record<RequestStatus, number>
) => {
  total[RequestStatus.APPROVED] = 0;
  total[RequestStatus.PENDING] = 0;
  total[RequestStatus.DENIED] = 0;

  listLeaveTypes.forEach((item) => {
    switch (item.manager_approved) {
      case RequestStatus.APPROVED:
        total[RequestStatus.APPROVED]++;
        break;
      case RequestStatus.PENDING:
        total[RequestStatus.PENDING]++;
        break;
      case RequestStatus.DENIED:
        total[RequestStatus.DENIED]++;
        break;
    }
  });
};

export function getLeaveBetweenDates(
  listManagerRequests: LeaveManagerPlanner[],
  startDate: Date,
  endDate: Date
): LeaveManagerPlanner[] {
  return listManagerRequests.map((entry) => ({
    ...entry,
    days: entry.days.filter((day) => {
      const leaveRequest = day.leave_request;
      if (!leaveRequest) {
        return false;
      }

      const firstDay = new Date(leaveRequest.first_last_days[0]);
      const lastDay = new Date(
        leaveRequest.first_last_days.length === 1
          ? leaveRequest.first_last_days[0]
          : leaveRequest.first_last_days[1]
      );

      return (
        firstDay.getTime() >= startDate.getTime() &&
        lastDay.getTime() <= endDate.getTime()
      );
    }),
  }));
}

export const updateHrRequestTotal = (
  listLeaveTypes: LeaveHrApproval[],
  total: Record<RequestStatus, number>
) => {
  total[RequestStatus.APPROVED] = 0;
  total[RequestStatus.PENDING] = 0;
  total[RequestStatus.DENIED] = 0;

  listLeaveTypes.forEach((item) => {
    switch (item.status) {
      case RequestStatus.APPROVED:
        total[RequestStatus.APPROVED]++;
        break;
      case RequestStatus.PENDING:
        total[RequestStatus.PENDING]++;
        break;
      case RequestStatus.DENIED:
        total[RequestStatus.DENIED]++;
        break;
    }
  });
};

export const filterByArchiveStatus = (
  list: LeaveManagerApproval[],
  selectedFilter: string
): LeaveManagerApproval[] => {
  return list.filter((item) => {
    if (selectedFilter === "Archive") {
      return !item.is_archived;
    } else if (selectedFilter === "Unarchive") {
      return item.is_archived;
    }
    return true;
  });
};

export const getCurrentDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  return `${day}-${month}-${year}`;
};

export const formatLeaveCount = (value: number) => {
  return value.toLocaleString();
};
