import moment from 'moment';
import { decodeUrlPath } from './utils';

export const getUserNameInitials = (name: string): string => {
  let words = name?.trim().split(' ');
  words = words?.length > 2 ? words.slice(0, 2) : words;
  return words?.map((word) => word[0]).join('') || '';
};

export const forMatNumbers = (number: string): string => {
  if (!number) return '';
  const numericOnly = number.trim().replace(/\D/g, '');
  if (numericOnly.startsWith('233')) {
    return `+233 ${numericOnly.substring(3, 6)} ${numericOnly.substring(6)}`;
  }
  if (numericOnly?.startsWith('0')) {
    return `+233 ${numericOnly.substring(1, 3)} ${numericOnly.substring(
      3,
      6
    )} ${numericOnly.substring(6)}`;
  }
  if (!number) return '';
  return `+233 ${numericOnly.substring(0, 2)} ${numericOnly.substring(
    2,
    6
  )} ${numericOnly.substring(6)}`;
};

export function getUserId(path: string) {
  const paths = path.split('=@');
  if (paths.length > 1) {
    return paths[1];
  }

  return decodeUrlPath(path);
}

//date Formating
export function formatDate(date: string) {
  return new Date(date).toISOString().split('T').splice(0, 1).join('') || '';
}

// time Formatting
export function formatTime(date: string) {
  const time = new Date(date).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
  return time || '';
}

export const formattedDate = (item: string, isUnix?: boolean) => {
  const dateObj = isUnix ? moment(parseInt(item)) : moment(item);
  const formattedDate = dateObj.format('Do MMMM YYYY');
  return formattedDate || '';
};

export function formattedTime(date: string, isUnix?: boolean) {
  const dateObj = isUnix ? moment(parseInt(date)) : moment(date);
  const formattedTime = dateObj.format('hh:mm A');
  return formattedTime || '';
}